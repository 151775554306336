<template>
  <p
    :name="id"
    :class="{ uneditable: uneditable, isRegions: isRegions }"
    class="field-wrapper"
  >
    <span v-if="label" class="label">
      <span>
        <label>
          {{ label }}
        </label>
      </span>
      <a v-if="hasHelp" class="dot" :id="'tooltipButton' + id"> ? </a>
      <b-tooltip
        v-if="hasHelp"
        placement="topleft"
        triggers="click"
        variant="primary"
        :target="'tooltipButton' + id"
        :title="help"
      >
      </b-tooltip>
    </span>

    <!--default-->
    <b-input
      v-if="isInput"
      :autocomplete="hasAutocomplete"
      :class="errorClass"
      :disabled="disabled"
      :formatter="formatValue"
      :inputmode="digits ? 'decimal' : ''"
      :placeholder="placeholder"
      :type="type"
      :value="value"
      @blur="focusChanged"
      @input="onChange($event)"
    />
    <span v-if="showCharCount" style="font-size: 0.7em; float: right">
      <span>{{ valueLength }}</span>
      <span>/</span>
      <span>{{ maxlength }}</span>
    </span>

    <!--Phone-->
    <input
      v-if="isPhone"
      v-model="phone"
      v-mask="'1 (###) ###-####'"
      class="form-control"
      type="text"
      :class="errorClass"
      :disabled="disabled"
      :placeholder="placeholder"
      @blur="focusChanged"
      @input="handlephone"
    />

    <!--Data list-->
    <span v-if="isDatalist" style="width:100%;">
      <b-input
        list="datalist"
        style="width:100%; max-width:100%;"
        type="text"
        :class="errorClass"
        :disabled="disabled"
        :formatter="formatValue"
        :inputmode="digits ? 'decimal' : ''"
        :placeholder="placeholder"
        :value="value"
        @input="onDatalistChange($event)"
        @click="toggleDataList = !toggleDataList"
      />
      <b-list-group v-if="toggleDataList" class="left">
        <b-list-group-item
          v-for="(option, index) in sortOptions"
          :key="index"
          @click="datalist(option.value)"
        >
          {{ option.text }}
        </b-list-group-item>
      </b-list-group>
    </span>

    <!--RegionSubregion-->
    <span v-if="isRegions" class="regionSubregionWidget-wrapper">
      <b-row>
        <b-col>
          <b-form-select
            v-model="region"
            class="port-selector region no-radius"
            :class="errorClass"
            :disabled="disabled"
            :options="regionOption"
          />
          <b-form-select
            v-model="subRegion"
            class="port-selector region-subregion no-radius"
            :class="errorClass"
            :disabled="disabled"
            :options="subRegionOption"
          />
        </b-col>
        <b-col>
          <b-form-select
            class="port-selector region-item no-radius"
            :class="errorClass"
            :disabled="disabled"
            :options="itemRegionOption"
            :value="value"
            @input="onChange($event)"
          />
        </b-col>
      </b-row>
    </span>

    <!--Tag-->
    <span
      v-if="isTags && tags.length > 0"
      :class="errorClass"
      class="tagsinput"
    >
      <span v-for="(tag, index) in tags" :key="'tag-' + index" class="tag">
        <span>{{ tag }}</span>
        <a @click="removeTag(index)" title="Removing tag">x</a>
      </span>
    </span>
    <input
      v-if="isTags"
      v-model="tag"
      :class="errorClass"
      :placeholder="$t('genericInput.tagsPlaceholder')"
      @blur="addTag"
      @keyup.enter="addTag"
    />
    <span v-if="isTags" :class="tagClass">
      {{ tags.length }}/{{ max && max !== "" && max !== null ? max : 0 }}
    </span>
    <!--Error-->
    <label
      v-if="isError || forceErrorMsg || forceErrorMsgNoBlock"
      class="error"
    >
      {{ errorMessage }}
    </label>
  </p>
</template>

<script>
import Validator from "@/utils/validator.js";

export default {
  name: "GenericInput",
  props: {
    value: {
      required: true
    },
    initialValue: {
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true,
      validator: function(value) {
        return [
          "text",
          "regionsubregion",
          "tags",
          "email",
          "phone",
          "datalist"
        ].includes(value);
      }
    },
    autocomplete: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    help: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    finValidator: {
      default: false
    },
    maxlength: {
      default: undefined
    },
    minlength: {
      default: undefined
    },
    min: {
      default: undefined
    },
    max: {
      default: undefined
    },
    digits: {
      type: Boolean,
      default: false
    },
    phoneUS: {
      default: false
    },
    arrayValidator: {
      type: Array,
      default: function() {
        return [];
      }
    },
    refeshError: {
      default: false
    },
    otherValue: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ""
    },
    forceErrorMsg: {
      default: undefined
    },
    forceErrorMsgNoBlock: {
      default: undefined
    },
    areasandports: {
      type: Array,
      default: function() {
        return [];
      }
    },
    uneditable: {
      type: Boolean,
      default: false
    },
    notBothFields: {
      default: undefined
    },
    bothFieldRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id:
        this.$options.name +
        "-" +
        this.type +
        "-" +
        (Math.random() * 100000).toFixed(0),
      isInput: false,
      isPhone: false,
      isDatalist: false,
      isRegions: false,
      isTags: false,
      hasAutocomplete: this.autocomplete,
      hasHelp: !!this.help,
      selected: null,
      tags: [],
      tag: "",
      tagClass: "tagometerUnder",
      phone: "",
      defaultErrorMsg: "",
      errorClass: { error: false },
      region: null,
      subRegion: null,
      regionOption: [{ value: null, text: "---------" }],
      subRegionOption: [{ value: null, text: "---------" }],
      itemRegionOption: [{ value: null, text: "---------" }],
      isError: false,
      toggleDataList: false
    };
  },
  computed: {
    filter() {
      return {
        type: this.type,
        required: this.required,
        min: this.min,
        max: this.max,
        digits: this.digits,
        finValidator: this.finValidator,
        maxlength: this.maxlength,
        minlength: this.minlength,
        phoneUS: this.phoneUS,
        arrayValidator: this.arrayValidator,
        notBothFields: this.notBothFields,
        bothFieldRequired: this.bothFieldRequired
      };
    },
    valueLength() {
      return this.value ? this.value.length : null;
    },
    showCharCount() {
      return this.isInput && this.maxlength > 40;
    },
    errorMessage() {
      var msg = this.defaultErrorMsg;
      if (this.forceErrorMsg) {
        msg = this.forceErrorMsg;
      } else if (this.errorMsg) {
        msg = this.errorMsg;
      } else if (this.forceErrorMsgNoBlock) {
        msg = this.forceErrorMsgNoBlock;
      }

      return msg;
    },
    sortOptions() {
      if (this.options) {
        return this.options.slice(0).sort(function(a, b) {
          if (!a.text) {
            return false;
          }
          return a.text.localeCompare(b.text, "en", { numeric: true });
        });
      } else {
        return [];
      }
    }
  },
  watch: {
    options() {
      this.checkOptionIfExist();
    },
    region() {
      this.getSubRegion();
    },
    subRegion() {
      this.getPort();
    },
    refeshError() {
      this.refreshErrorMethod();
    },
    selected() {
      this.onChange(this.selected);
      this.checkOptionIfExist();
    },
    tags() {
      this.setError(this.tags);
      this.$emit("input", this.tags.join(";"));
    },
    isError() {
      this.errorClass.error =
        this.isError ||
        this.forceErrorMsg?.length > 0 ||
        this.forceErrorMsgNoBlock;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    arrayValidator() {
      this.filter.arrayValidator = this.arrayValidator;
    },
    value() {
      if (this.type === "tags") {
        if (this.value) {
          this.tags = this.value
            .split(";")
            .reduce(function(reducedArrayOfTags, tag) {
              let trimmedTag = tag.trim();
              if (trimmedTag !== "") {
                reducedArrayOfTags.push(trimmedTag);
              }
              return reducedArrayOfTags;
            }, []);
        }
      }
    }
  },
  directives: {
    formatWithComma: {
      update(e) {
        if (e.dataset.prevValue.length > 6) {
          e.selectionEnd = e.dataset.prevValue.length + 6;
        } else if (e.dataset.prevValue.length > 3) {
          e.selectionEnd = e.dataset.prevValue.length + 5;
        } else {
          e.selectionEnd = e.dataset.prevValue.length + 3;
        }
      }
    }
  },
  methods: {
    checkOptionIfExist() {
      if (this.selected && this.options) {
        if (!this.options.some(x => x.value === this.selected)) {
          this.selected = null;
        }
      }
    },
    refreshErrorMethod() {
      if (this.type === "phone") {
        this.setError(this.phone);
      } else if (this.type === "tags") {
        this.setError(this.tags);
      } else {
        this.setError(this.value);
      }
    },
    addTag() {
      if (this.tag !== "") {
        this.tags.push(this.tag);
        this.tag = "";
      }
    },
    removeTag(index) {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("genericInput.tagWarning"),
        icon: "warning",
        buttons: [this.$t("cancel"), this.$t("yes")],
        dangerMode: true
      }).then(willBeClosed => {
        if (willBeClosed) {
          this.tags.splice(index, 1);
        }
      });
    },
    setError(value) {
      const rt = Validator.genericValidation(value, this.filter);
      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.errorClass.error =
        this.isError ||
        this.forceErrorMsg?.length > 0 ||
        this.forceErrorMsgNoBlock;
      let object = {};
      object[this.id] = this.errorClass.error;
      if (this.isTags) {
        if (this.errorClass.error === true) {
          this.tagClass = "tagometerError";
        } else if (this.max === this.tags.length) {
          this.tagClass = "tagometer";
        } else {
          this.tagClass = "tagometerUnder";
        }
      }

      if (rt.notBlocked || this.forceErrorMsgNoBlock) {
        return;
      }
      this.$emit("error", object);
    },
    handlephone(e) {
      let phone = this.phone.replace(/\D/g, "").replace(1, "");
      this.$emit("input", phone);
    },
    datalist(value) {
      if (this.otherValue) {
        if (value && value !== null) {
          value = value.split("??");
          if (value[0] && value[0] !== "null" && value[0] !== null) {
            this.$emit("input", value[0]);
            if (this.filter.digits) {
              this.setError(Number(value[0]));
            } else {
              this.setError(value[0]);
            }
          }
          this.$emit("binding", value);
        }
      } else {
        this.$emit("input", value);
        if (this.filter.digits) {
          this.setError(Number(value));
        } else {
          this.setError(value);
        }
      }
      this.toggleDataList = false;
    },
    getRegion() {
      this.regionOption = [{ value: null, text: "---------" }];
      for (let i = 0; i < this.areasandports.length; i++) {
        this.regionOption.push({
          value: i,
          text: this.areasandports[i].name
        });
      }
      this.regionOption.sort(function(a, b) {
        return a.text.localeCompare(b.text);
      });
    },
    getSubRegion() {
      this.subRegionOption = [{ value: null, text: "---------" }];
      this.subRegion = null;
      if (this.region !== null) {
        let currentSubregion = this.areasandports[this.region].subregions;
        for (let index in currentSubregion) {
          this.subRegionOption.push({
            value: index,
            text: currentSubregion[index].name
          });
        }

        this.subRegionOption.sort(function(a, b) {
          return a.text.localeCompare(b.text);
        });
      }
    },
    getPort() {
      this.itemRegionOption = [{ value: null, text: "---------" }];
      if (this.subRegion !== null) {
        const subregion = this.areasandports[this.region].subregions[
          this.subRegion
        ];
        for (let i = 0; i < subregion.ports.length; i++) {
          const port = subregion.ports[i];
          this.itemRegionOption.push({
            value: port.id,
            text: port.desc.toLowerCase()
          });
        }
      }

      this.itemRegionOption.sort(function(a, b) {
        return a.text.localeCompare(b.text);
      });
    },
    setPort() {
      if (this.value) {
        for (let i = 0; i < this.areasandports.length; i++) {
          for (let j = 0; j < this.areasandports[i].subregions.length; j++) {
            let ports = this.areasandports[i].subregions[j].ports;
            for (let k = 0; k < ports.length; k++) {
              if (this.value === ports[k].id) {
                this.region = i;
                this.$nextTick(() => {
                  this.subRegion = j;
                  this.$nextTick(() => {
                    this.$emit("input", this.value);
                  });
                });
              }
            }
          }
        }
      }
    },
    focusChanged(e) {
      this.setError(e.target.value);
      if (!this.isDatalist) {
        let value = e.target.value;
        this.$emit("binding", value);
      }
    },
    onDatalistChange(value) {
      this.setError(value);
      this.onChange(value);
    },
    onChange(value) {
      if (value === undefined || value === "" || value === null) {
        value = null;
      } else {
        if (this.digits) {
          value = parseFloat(value);
        }
      }
      this.$emit("input", value);
    },
    formatValue(value) {
      let x = value;
      if (this.filter.digits) {
        x = x.trim();
        x = x.replace(/[^0-9\.\,]/g, "");
      }
      return x;
    }
  },
  mounted() {
    let value = this.value;
    if (this.initialValue) {
      value = this.initialValue;
    }
    if (this.type === "phone") {
      if (value !== null && value) {
        this.phone = value;
      }
    } else if (this.type === "tags") {
      if (value) {
        this.tags = value.split(";");
        this.setError(this.tags);
      }
    } else if (this.type === "regionsubregion") {
      if (this.areasandports) {
        this.getRegion();
        this.$nextTick(() => {
          this.setPort();
        });
      }
    }
  },
  created() {
    if (this.type === "phone") {
      this.isPhone = true;
    } else if (this.type === "datalist") {
      this.isDatalist = true;
    } else if (this.type === "tags") {
      this.isTags = true;
    } else if (this.type === "regionsubregion") {
      this.isRegions = true;
    } else if (this.type) {
      // This else is Nonsense.
      // GenericInput supports a finite list of types.
      // We cannot assume that any non-recognized type is an Input type !!
      this.isInput = true;
    }
  },
  beforeDestroy() {
    let object = {};
    object[this.id] = false;
    this.$emit("error", object);
  }
};
</script>
